import _checkPropTypes from 'prop-types/checkPropTypes';
import React, { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const JobFormContext = createContext(null);

const initialState = {
  jobData: null,
  ahjs: [],
  utilities: [],
  jobFilesToUpload: {
    job_files: [],
    electrical_photos_on_cad: [],
    attic_photos_on_cad: []
  },
  // array of objects with each object containing a field name and the error message?
  // currentStepErrors: [],
  currentStepHasErrors: false,
  currentStepHasChanges: false,
  formIsLoading: false
};

export const JOB_FORM_TYPES = {
  SET_INITIAL_CONTEXT: 'JOBS/SET_INITIAL_CONTEXT',
  UPDATE_JOB_DATA: 'JOBS/UPDATE_JOB_DATA',
  UPDATE_STEP_HAS_CHANGES: 'JOBS/UPDATE_STEP_HAS_CHANGES',
  UPDATE_AHJS_AND_UTILITIES: 'JOBS/UPDATE_AHJS_AND_UTILITIES',
  UPDATE_CURRENT_STEP_ERRORS: 'JOBS/UPDATE_CURRENT_STEP_ERRORS',
  UPDATE_FORM_IS_LOADING: 'JOBS/UPDATE_FORM_IS_LOADING'
};

const reducer = (state, action) => {
  switch (action.type) {
    case JOB_FORM_TYPES.SET_INITIAL_CONTEXT:
      return {
        ...state,
        jobData: action.payload.jobData,
        jobFilesToUpload: {
          job_files: [],
          electrical_photos_on_cad: [],
          attic_photos_on_cad: []
        },
        currentStepHasChanges: false,
        ahjs: [],
        utilities: [],
        formErrors: {},
        formIsLoading: false
      };
    case JOB_FORM_TYPES.UPDATE_JOB_DATA:
      return {
        ...state,
        jobData: action.payload.jobData || state.jobData,
        jobFilesToUpload:
          action.payload.jobFilesToUpload || state.jobFilesToUpload,
        currentStepHasChanges:
          'currentStepHasChanges' in action.payload
            ? action.payload.currentStepHasChanges
            : false,
        formIsLoading:
          'formIsLoading' in action.payload
            ? action.payload.formIsLoading
            : state.formIsLoading
      };
    case JOB_FORM_TYPES.UPDATE_STEP_HAS_CHANGES:
      return {
        ...state,
        currentStepHasChanges: action.payload
      };
    case JOB_FORM_TYPES.UPDATE_AHJS_AND_UTILITIES:
      return {
        ...state,
        ahjs: action.payload.ahjs || state.ahjs,
        utilities: action.payload.utilities || state.utilities,
        formIsLoading:
          'formIsLoading' in action.payload
            ? action.payload.formIsLoading
            : state.formIsLoading
      };
    case JOB_FORM_TYPES.UPDATE_CURRENT_STEP_ERRORS:
      return {
        ...state,
        currentStepHasErrors: action.payload
      };
    case JOB_FORM_TYPES.UPDATE_FORM_IS_LOADING:
      return {
        ...state,
        formIsLoading: action.payload
      };
    default:
      return state;
  }
};

const JobFormProvider = ({ children }) => {
  _checkPropTypes(
    {
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
        .isRequired
    },
    {
      children
    },
    'prop',
    'JobFormProvider'
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <JobFormContext.Provider value={value}>{children}</JobFormContext.Provider>
  );
};

export { JobFormContext, JobFormProvider };
